/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Admin from "../views/Admin.vue";
import Import from "../views/Import.vue";
import Template from "../views/Template.vue";
import Templates from "../views/Templates.vue";
import Teams from "../views/Teams.vue";
import Matrix from "../views/Matrix.vue";
import Users from "../views/Users.vue";
import Privacy from "../views/Privacy.vue";
import Cycles from "../views/Cycles.vue";
import Cycle from "../views/Cycle.vue";
import CycleDetail from "../views/CycleDetail.vue";
import UserTeams from "../views/UserTeams.vue";
import Profile from "../views/Profile.vue";
import Permissions from "../views/Permissions.vue";
import Results from "../views/Results.vue";
import ReportErrors from "../views/ReportErrors.vue";
import Reports from "../views/Reports.vue";
import Summary from "../views/Summary.vue";
import ManageMetrics from "../views/ManageMetrics.vue";
import AppReport from "../views/AppReport.vue";
import Help from "../views/Help.vue";

import store from "../store"
import { Gems } from "../gems";
import { KPI } from "../kpi";

const routes = [
  {
    path: "/",
    name: "login",
    meta: {
      cleanLayout: true,
      loginLayout: true,
      requiresAuth: false,
      requiresAdmin: false,
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: "/import",
        name: "import",
        component: Import,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },
      {
        path: "/permissions",
        name: "permissions",
        component: Permissions,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },
      {
        path: "/template",
        name: "template",
        component: Template,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },
      {
        path: "/templates",
        name: "templates",
        component: Templates,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },
      {
        path: "/teams",
        name: "teams",
        component: Teams,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },
      {
        path: "/cycle",
        name: "cycle",
        component: Cycle,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },
      {
        path: "/cycle-detail",
        name: "cycle-detail",
        component: CycleDetail,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },
      {
        path: "/results",
        name: "results",
        component: Results,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },
      {
        path: "/manage-metrics",
        name: "manage-metrics",
        component: ManageMetrics,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },

      {
        path: "/report-errors",
        name: "report-errors",
        component: ReportErrors,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      },

      {
        path: "/app-report",
        name: "app-report",
        component: AppReport,
        props: true,
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          masterNode: "/admin",
        },
      }
    ],
  },
  {
    path: "/matrix",
    name: "matrix",
    component: Matrix,
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy,
    props: true,
    meta: {
      cleanLayout: true,
      loginLayout: false,
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/cycles",
    name: "cycles",
    component: Cycles,
    meta: {
      cleanLayout: true,
      loginLayout: false,
      requiresAuth: true,
      requiresAdmin: false,
    }
  },
  {
    path: "/user-teams",
    name: "user-teams",
    component: UserTeams,
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/help",
    name: "help",
    component: Help,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/summary",
    name: "summary",
    component: Summary,
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: false,
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      cleanLayout: true,
      loginLayout: true,
      requiresAuth: false,
      requiresAdmin: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

async function Authentication() {
  let res = await KPI.CallBackEnd("post", "router-check", { cycle: localStorage.getItem("cycle") }).then((response) => {
    if (response.status == 200) {
      const item = {
        value: response.data['status'] == 5 ? true : false,
      };
      const admin = {
        value: response.data['admin'] != null ? response.data['admin'] : 0,
      };
      const entity = {
        value: response.data['entity'] != null ? response.data['entity'] : 0,
      };
      localStorage.setItem("auth", JSON.stringify(item));
      localStorage.setItem("admin", JSON.stringify(admin));
      localStorage.setItem("entity", JSON.stringify(entity));
      localStorage.setItem("cycle", response.data['cycle']);

      return [item.value, admin.value, entity.value];
    } else {
      const item = {
        value: false,
      };
      const admin = {
        value: 0,
      };
      localStorage.setItem("auth", JSON.stringify(item));
      localStorage.setItem("admin", JSON.stringify(admin));
      return false;
    }
  });
  return res;
}

async function LogOut() {
  await KPI.CallBackEnd("post", "logout").then((response) => {
    if (response.status == 200) {
      const item = {
        value: false,
      };
      localStorage.setItem("auth", JSON.stringify(item));
      sessionStorage.removeItem("logged_user_id");

      window.location.href = `${Gems.HubUrl()}/logout`
    }
  });
}

function CheckForAdmin(adminRequired, admin, cycle, next) {
  if (adminRequired && !admin) {
    Gems.Telegram("Acesso negado", "tele-not-allowed");
    if (cycle == "null" || cycle == null) {
      next("/cycles");
    } else {
      next("/matrix");
    }
  } else {
    next();
  }
}

function CheckCycleChoosed(cycle, path, next) {
  if ((cycle == "null" || cycle == null || cycle == undefined || cycle == "undefined") && (path != '/cycles' && path != '/cycle')) {
    Gems.Telegram("Selecione um ciclo", "tele-success")
    next("/cycles")
  }
}


function PermitedRoutes(actualRoute) {
  const permitedRoutes = ["Cycle", "Cycles", "login", "cycle-detail", "Privacy", "Recovery"]

  for (let route in permitedRoutes)
    if (permitedRoutes[route] === actualRoute)
      return true

  return false
}

router.afterEach((to, from) => {
  document.body.scrollTop = 0;
});

router.beforeEach(async (to, from, next) => {
  let res
  document.title = "CIRVALUE KPI - Gestão de performance"
  const cycle = localStorage.getItem("cycle")

  switch (to.path) {
    case '/logout':
      await LogOut()
      next()
      break;
    case '/':
      const checked = await KPI.CallBackEnd("get", "check-credentials", { params: { cycle_id: cycle }, });

      if (checked?.data?.status == 5 || checked?.data?.status == 1) {
        CheckCycleChoosed(cycle, to.path, next)
        next("/matrix")
      }

      break;
    case '/matrix':
      CheckCycleChoosed(cycle, to.path, next)
      // let releases = await KPI.CallBackEnd("get", "get-latest-releases");

      // if (Object.keys(releases.data).length > 0 && !releases.data.error) {
      //   Object.assign(to.params, { releasesArr: releases.data, activateModal: true });
      // } else {
      //   Object.assign(to.params, { activateModal: false });
      // }
      next();

      break;
    default:
      res = await Authentication();
      const authRequired = to.matched.some((route) => route.meta.requiresAuth);
      const adminRequired = to.matched.some((route) => route.meta.requiresAdmin);
      let authenticated = JSON.parse(localStorage.getItem("auth")).value;
      let admin = KPI.isAdmin();

      if (authRequired && !authenticated)
        next("/")

      if (to.path != '/profile' && to.path != '/users')
        store.dispatch('RestoreSitemap')

      if (PermitedRoutes(to.name)) {
        CheckForAdmin(adminRequired, admin, cycle, next)
      } else {
        CheckCycleChoosed(cycle, to.path, next)
        CheckForAdmin(adminRequired, admin, cycle, next)
      }
  }
});

export default router;
