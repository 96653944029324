import { KPI } from "../../kpi";
import { Gems } from "../../gems";

const getDefaultState = () => {
  return {
    arrTemplates: [],
    template: [],
  };
};

const state = {
  template: [],
  arrTemplates: [],
};

const getters = {
  arrTemplate: (state) => state.template,
  arrTemplates: (state) => state.arrTemplates,
};

const actions = {
  async GetTemplates({ commit }) {
    try {
      const response = await KPI.CallBackEnd("get", "get-templates", {
        params: { cycle_id: localStorage.getItem("cycle") },
      });
      commit("storeTemplates", response.data)
    } catch (error) {
      Gems.Telegram(KPI.ERROR_MESSAGE_GENERIC, "tele-not-allowed");
    }
  },

  async FetchTemplate({ commit }, args) {
    await KPI.CallBackEnd("get", "get-template", {
      params: { temp_id: args, cycle_id: localStorage.getItem("cycle") },
    }).then((response) => {
      commit("storeTemplate", response.data)
    })
  },

  ValidateWeights({ state }) {
    const templates = state.template
    let scopeSum = 0
    let error = 0

    Object.values(templates.SCOPES).forEach(scope => {
      if (scope.sco_id != 1)
        scopeSum += parseFloat(scope.sco_weight)
      if (scope.INDICATORS.length > 0 && scope.sco_id == 3) {
        let indicatorSum = 0
        indicatorSum = scope.INDICATORS.reduce((accumulator, indicator) => accumulator += parseFloat(indicator.ind_weight), indicatorSum)

        if (indicatorSum != 100) {
          Gems.Telegram(`A soma dos pesos dos indicadores [${scope.sco_desig.toUpperCase()}] precisa ser igual a 100`, "tele-not-allowed")
          error++
        }
      }
    })

    if (scopeSum != 100 && error == 0) {
      Gems.Telegram("A soma dos pesos dos agrupamentos precisa ser igual a 100", "tele-not-allowed")
      error++
    }

    if (error != 0)
      return false

    return true
  },

  async SaveTemplate({ dispatch }) {

    if (!await dispatch('ValidateWeights'))
      return

    const response = await KPI.CallBackEnd("post", "update-template", {
      arrTemplate: state.template,
    });

    if (response.status != 200 || response.error) {
      Gems.Telegram("Ocorreu um erro", "tele-not-allowed");
      return false
    }

    if (response.data.error) {
      Gems.Telegram(response.data.message, 'tele-not-allowed')
      return false
    }

    Gems.Telegram("Modelo gravado", "tele-success");
    return true
  },

  IsTemplateNameValid({ state }, { temp_id, temp_desig }) {
    if (temp_desig.replace(/\s/g, '').toLowerCase().trim() == '') {
      Gems.Telegram("Informe o nome do modelo", "tele-not-allowed")
      return false
    }

    for (let template in state.arrTemplates) {
      if (state.arrTemplates[template]["temp_desig"].replace(/\s/g, '').toLowerCase().trim() == temp_desig.replace(/\s/g, '').toLowerCase().trim() && state.arrTemplates[template]["temp_id"] != temp_id) {
        Gems.Telegram("Nome já existente", "tele-not-allowed")
        return false
      }
    }

    return true
  }
};

const mutations = {
  storeTemplate: (state, template) => state.template = template,

  storeTemplates: (state, templates) => state.arrTemplates = templates,

  storeMetrics: (state, metrics) => state.metrics = metrics,

  updateScope: (_, params) => params.scope.sco_weight = params.weight,

  updateIndicator: (_, params) => Object.assign(params.indicator, params.new_indicator),

  addIndicator: function (state, indicator) {
    const scopes = Object.values(state.template.SCOPES)
    const sco_ind = scopes.findIndex((scope) => scope.sco_id == indicator.sco_id)

    scopes[sco_ind].INDICATORS.push(indicator)
  },

  removeIndicator: function (state, indicator) {
    const scopes = Object.values(state.template.SCOPES)
    const sco_ind = scopes.findIndex((scope) => scope.sco_id == indicator.sco_id)
    const indicator_ind = scopes[sco_ind].INDICATORS.findIndex((ind) => ind.ind_id == indicator.ind_id)

    scopes[sco_ind].INDICATORS.splice(indicator_ind, 1)
  },

  resetTemplate(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
