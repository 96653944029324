<template>
  <tr :class="{'without-result-row' : !KPI.isNumeric(objIndicator.ind_vol)}" ref="indicator">
    <td :class="{'without-result-line' : !KPI.isNumeric(objIndicator.ind_vol)}"></td>

    <td class="td-id">{{ objIndicator.ind_internal }}</td>

    <td class="td-desig" ref="designation">
      <span class="line-clamp text-truncate">{{ objIndicator.ind_desig }}</span>
    </td>

    <td class="td-weight">
      <input type="text" ref="txtWeight" class="txt" maxlength="4" :value="objIndicator.ind_weight" @change="Update('txtWeight')" />
    </td>

    <td class="td-metric" ref="metric">  
      <span class="line-clamp text-truncate">{{ objIndicator.ind_met }}</span>
    </td>

    <td class="td-unit">{{ objIndicator.ind_unit }}</td>

    <td class="td-goal">
      <input type="text" v-if="objIndicator.ind_met_id != 7" ref="txtGoal" class="txt" :value="objIndicator.ind_goal" @change="Update('txtGoal')"/>
    </td>

    <td class="td-mark" v-if="objIndicator.ind_met_id < 5">
      <input type="text" ref="txtMark" class="txt" :value="objIndicator.ind_mark" @change="Update('txtMark')"/>
    </td>
    <td class="td-mark" v-else></td>

    <td class="td-result">
      <div v-if="objIndicator.ind_met_id == 7">
        <div v-if="objIndicator?.METRICS?.length > 0">
          <input v-if="objIndicator.direct_matching == 0" type="text"
            class="txt-result" :value="objIndicator.ind_vol" ref="txtVolMetric0"
            @keyup="
              $store.commit('calculateEvaluation', {
                indicator: objIndicator,
                volume: this.$refs.txtVolMetric0.value,
              })"
          />
          <!-- <Dropdown v-else :text="objIndicator.title ?? objIndicator.METRICS.length" :interval="true" :isOpen="openDDL" :tooltip="objIndicator.tooltip" @toggle="openDDL = !openDDL">
            <DropdownItem v-for="(row, index) in objIndicator.METRICS" :key="index" :tooltip="row.tooltip" @percentage="SetDirectMatchingEvaluation(objIndicator, row)" :interval="true" >
              {{ row.option_value }}
            </DropdownItem>
          </Dropdown> -->

          <select class="ddl-metrics" v-else v-model="selectedMetric" @change="SetDirectMatchingEvaluation()">
            <option :value="null" selected>--</option>
            <option v-for="(interval, index) in objIndicator.METRICS" :key="index" :interval="true" :title="interval.tooltip" :value="index">
              {{ interval.option_value }}
            </option>
          </select>

        </div>
        <div v-else ref="disabled">
          <input type="text" class="txt-result" disabled />
        </div>
      </div>
      <div v-else-if="objIndicator.ind_met_id == 5 || objIndicator.ind_met_id == 6">
        <IndicatorBooleanVolume :objIndicator="objIndicator" @ChangeRadio="ChangeRadio($event)" />
      </div>
      
      <input type="text" v-else class="txt-result" ref="txtVol" :placeholder="!KPI.isNumeric(objIndicator.ind_vol) ? '--' : ''"
        :value="KPI.isNumeric(objIndicator.ind_vol) ? objIndicator.ind_vol : ''"
        @change="$store.commit('calculateEvaluation', { indicator: objIndicator, volume: this.$refs?.txtVol?.value, })" />
    </td>

    <td class="td-realization" ref="reali">{{ objIndicator.ind_realization || KPI.isNumeric(objIndicator.ind_vol) ? Gems.FormatDecimals(objIndicator.ind_realization, 2) + '%' : '--' }}</td>

    <td class="td-realization" ref="eval">{{ objIndicator.ind_realization || KPI.isNumeric(objIndicator.ind_vol) ? Gems.FormatDecimals(objIndicator.ind_evaluation, 2) + '%' : '--' }}</td>
    
    <td class="td-above100" >
      <select class="ddl-above100" :class="{ 'cursor-pointer': objIndicator.ind_met_id != 5 && objIndicator.ind_met_id != 6}" ref="above100" @change="Update('above100')" v-if="objIndicator.ind_met_id != 5 && objIndicator.ind_met_id != 6">
        <option :selected="objIndicator.above100 == KPI.ABOVE100.NONE" :value="KPI.ABOVE100.NONE">N/A</option>
        <option :selected="objIndicator.above100 == KPI.ABOVE100.FA" :value="KPI.ABOVE100.FA">FA</option>
        <option :selected="objIndicator.above100 == KPI.ABOVE100.FO" :value="KPI.ABOVE100.FO">FO</option>
      </select>
    </td>
    <td></td>

    <td class="td-gauge cursor-pointer" @click="Show('chat')" ref="commentIcon">
      <i class="fas fa-comments" aria-hidden="true"></i>
    </td>
  </tr>
  
  <MatrixTrRow
    :objIndicator="objIndicator"
    :chat="chat"
    :gauge="gauge"
    :cycleDetail="true"
    colspan="15"
  />
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { KPI } from "../kpi";
import { Gems } from "../gems";
// import { Dropdown, DropdownItem } from "./dropdown/";
import MatrixTrRow from "./MatrixTrRow.vue";
import IndicatorBooleanVolume from "./IndicatorBooleanVolume.vue";
import { useTableMetric } from "../composables/useTableMetric"

export default {
  name: "CycleIndicatorBlock",
  setup(){
    return {  useTableMetric }
  },
  props: {
    objIndicator: {},
    cycle: Boolean,
    company: Boolean,
    cycle_scope_id: String,
    templateId: Number,
    cycle_id: [String, Number],
  },
  components: {
    // Dropdown,
    // DropdownItem,
    MatrixTrRow,
    IndicatorBooleanVolume,
  },
  data() {
    return {
      template_id: Number,
      scope_id: Number,
      indicator: Object,
      openDDL: false,
      radio: Boolean,
      gauge: false,
      chat: false,
      Gems,
      KPI,
      selectedMetric: false
    };
  },
  methods: {
    ...mapMutations([
      "updateIndicatorCycle",
      "calculateEvaluation",
      "calculateDirectMatchingEvaluation"
    ]),

    ...mapActions(["ShowGauge", "ValidateIndicator"]),

    async Show(type) {
      const res = await this.ShowGauge(
        {
          gauge: this.gauge,
          chat: this.chat,
          commentIcon: this.$refs.commentIcon,
          gaugeIcon: this.$refs.gaugeIcon,
          indicator: this.$refs.indicator,
          type
        }
      )

      this.gauge = res[0]
      this.chat = res[1]
    },

    async Update(refName) {
      const auxIndicator = []

      Object.assign(auxIndicator, {
        ind_weight: this.$refs.txtWeight.value,
        ind_goal: auxIndicator.ind_met_id == 7 ? null : this.$refs?.txtGoal?.value,
        ind_mark: auxIndicator.ind_met_id >= 5 ? null : this.$refs?.txtMark?.value,
        ind_met_id: this.objIndicator.ind_met_id,
        above100: this.$refs.above100.value
      })

      const res = await this.ValidateIndicator(auxIndicator)
      if (!res) {
        this.$refs[refName].value = ""
        return
      }

      this.updateIndicatorCycle({indicator: this.objIndicator, new_indicator: auxIndicator})

      if (this.objIndicator.ind_met_id != 7)
        this.calculateEvaluation({ indicator: this.objIndicator, volume: this.objIndicator.ind_vol,})
    },

    SetDirectMatchingEvaluation() {
      if(this.selectedMetric == null) {
        Object.assign(this.objIndicator, { ind_vol: null });
        return 
      }

      this.calculateDirectMatchingEvaluation({ indicator: this.objIndicator, optionSelected: this.objIndicator.METRICS[this.selectedMetric] });
      this.openDDL = false;
    },

    ChangeRadio(volume) {
      Object.assign(this.objIndicator, {
        ind_vol: volume,
      })

      this.calculateEvaluation({
        indicator: this.objIndicator,
        volume: this.objIndicator.ind_vol,
      });
    },
  },
  async mounted() {
    if(this.objIndicator.ind_met_id == 7) {
      await this.useTableMetric(this.objIndicator)

      if(this.objIndicator.direct_matching) {
        this.objIndicator.METRICS.forEach((metric, i) => {
          if(metric.evaluation == this.objIndicator.ind_vol)
          this.selectedMetric = i
        })
      }
    }
    
    let arr = KPI.GetMatrixArrTooltips(this.$refs, this.objIndicator);
    KPI.CheckForRefsTooltips(arr);
  },
  updated() {
    let arr = KPI.GetMatrixArrTooltips(this.$refs, this.objIndicator);
    KPI.CheckForRefsTooltips(arr);
  },
};
</script>

<style scoped>
.td-realization {
  cursor: default;
}

.td-desig {
  width: 24%;
}

.td-weight {
  width: 9%;
}

.td-gauge {
  width: 6%;
}

.fa-comments {
  font-size: 1.3em;
}

.ddl-metrics {
  width: 100%;
  padding: 0px 5px;
}
</style>
