<template>
  <div class="inside-header" :class="{'tab-header': countMatrix > 1}">
    <div class="left">
      <h3 class="function" ref="function" @mouseenter="Gems.TooltipIfNeeded($refs.function)">
        {{ arrScopes.job_description }}
      </h3>
      <p>Data de importação: {{ arrScopes.last_import }}</p>
      <p>Data dos resultados: {{ arrScopes.file_date }}</p>
      <p>Data da gravação: {{ arrScopes.last_update }}</p>
    </div>
    <div class="right">

      <div class="div-btns">
        <div class="btn-save">
          <i @click="SaveMatrix([userId, arrScopes, arrScopes.team_id, permission])" ref="icoSave" class="fas fa-save"
            v-if="permission == true"></i>
        </div>

        <div class="exports">
          <i @click="ExportMatrix()" ref="icoDownload" class="fas fa-download"></i>
        </div>

        <div class="btn-save">
          <i @click="PrintWindow()" ref="icoPrint" class="fas fa-print"></i>
        </div>

        <div class="btn-show-all">
          <i :class="showMatrix ? 'far fa-caret-square-up' : 'far fa-caret-square-down'"
            class="icon fa-2x cursor-pointer" ref="icoShow" aria-hidden="true" @click="ToggleCollapseScopes()"></i>
        </div>
      </div>

      <div v-for="(type, index) in EvaluationType" :key="index" class="evaluation-type">
        <select v-model="selected" :ref="`evaluation${index}`" class="select-evaluation cursor-pointer"
          @mouseenter="Gems.Tooltip($refs[`evaluation${index}`][0], 'Opções de apuramento', 'tooltip')">
          <option v-if="type['integrated']" value="1" :title="type['integrated_hint']">
            Integrado
          </option>
          <option v-if="type['spec']" value="2" :title="type['spec_hint']">
            Específico
          </option>
          <option v-if="type['weighted']" value="3" :title="type['weighted_hint']">
            Ponderado
          </option>
        </select>
      </div>

      <div class="div-auto-weight" v-if="admin && countMatrix > 1" ref="balanceWeight">
        <label for="">Equilibrar pesos das matrizes</label>
        <input type="checkbox" v-model="balanceWeight">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "MatrixInsideHeader",
  emits: ["change-evaluation-type"],
  props: {
    arrScopes: Object,
    evalType: [String, Number],
    permission: Boolean,
    rendered: Boolean,
    countMatrix: Number,
  },
  data() {
    return {
      admin: Boolean,
      Gems,
      showMatrix: true,
    };
  },
  methods: {
    ...mapActions(["SaveMatrix", "EvaluationSelected", "UserEvaluation"]),

    ...mapMutations(["setBalanceWeight"]),
    // Don't use yet
    async ExportMatrix() {
      await KPI.CallBackEnd("post", "export", {
        cycle: localStorage.getItem("cycle"),
        matrix: this.arrScopes,
        evaluationSelected: this.evalType,
        type: 2,
        evaluation: this.MatrixEvaluation,
        userId: this.UserIdState,
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data) {
              Gems.ExportExcel(res.data);
            }
          }
        })
    },
    PrintWindow() {
      window.print();
    },
    SetTooltip() {
      if (this.$refs.icoSave)
        Gems.Tooltip(this.$refs.icoSave, "Gravar (todas as matrizes)", "tooltip")

      if (this.$refs.icoPrint)
        Gems.Tooltip(this.$refs.icoPrint, "Imprimir", "tooltip")

      if (this.$refs.icoDownload)
        Gems.Tooltip(this.$refs.icoDownload, "Exportar", "tooltip")

      if (this.$refs.icoShow)
        Gems.Tooltip(this.$refs.icoShow, "Expandir/Ocultar tudo", "tooltip")

      if (this.$refs.balanceWeight)
        Gems.Tooltip(this.$refs.balanceWeight, 'Os pesos das matrizes serão automaticamente distribuídos equitativamente pelas matrizes com peso maior que "0" (zero)', 'tooltip')
    },
    ToggleCollapseScopes() {
      this.showMatrix = !this.showMatrix
      document.querySelectorAll('#show-scope').forEach(elem => {
        if (this.showMatrix && elem.classList.contains('fa-sort-down'))
          elem.click()

        if (!this.showMatrix && elem.classList.contains('fa-sort-up'))
          elem.click()
      })
    },
  },
  computed: {
    ...mapGetters([
      "Matrix",
      "UserIdState",
      "Show",
      "EvaluationType",
      "MatrixEvaluation",
      "GetEvaluationSelected",
      "BalanceWeight"
    ]),

    selected: {
      get() {
        return this.GetEvaluationSelected;
      },
      set(value) {
        this.EvaluationSelected(value);
      }
    },

    balanceWeight: {
      get() {
        return this.BalanceWeight
      },
      set(value) {
        this.setBalanceWeight(value)
        this.UserEvaluation()
      }
    }
  },
  mounted() {
    if (this.rendered === false) {
      for (let type in this.EvaluationType) {
        if (this.EvaluationType[type]["spec"] == true) {
          this.selected = 2;
        } else {
          if (this.EvaluationType[type]["integrated"] == true) {
            this.selected = 1;
          } else {
            this.selected = 3;
          }
        }
      }
    }
    this.SetTooltip()
    this.admin = KPI.isAdmin()
  },
  updated() {
    if (this.rendered === false) {
      for (let type in this.EvaluationType) {
        if (this.EvaluationType[type]["spec"] == true) {
          this.selected = 2;
        } else {
          if (this.EvaluationType[type]["integrated"] == true) {
            this.selected = 1;
          } else {
            this.selected = 3;
          }
        }
      }
    }
    this.SetTooltip();
  },
};
</script>

<style scoped>
.btn-save button {
  width: 200px;
  height: 40px;
}

.btn-show-all {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 70%;
}

.btn-save i,
.exports i {
  font-size: 1.2em;
}

.exports {
  display: flex;
}

.exports button {
  width: 98px;
}

.exports button:nth-child(1) {
  margin-right: 5px;
}

.right,
.left {
  min-width: max-content;
}

.left p {
  font-size: .85rem;
}

.left h3 {
  font-weight: bold;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  min-width: 210px;
}

.right span {
  display: block;
  width: 100%;
  text-align: end;
}

.inside-header {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  align-items: center;
}

p {
  margin: 0px;
}

.div-btns {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  place-items: center;
  width: 70%;
}

.div-btns i {
  cursor: pointer;
}

.function {
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: default;
  font-size: 1.2em;
}

.is-disabled {
  pointer-events: none;
}

.fa-sort-up {
  transform: translateY(5px);
}

.fa-sort-down {
  transform: translateY(-5px);
}

.select-evaluation {
  width: -webkit-fill-available;
}

.evaluation-type {
  margin-top: 5px;
  width: 100%;
}

.div-auto-weight {
  display: flex;
  align-items: center;
  font-size: .95rem;
  justify-content: flex-end;
}

.clearance {
  font-size: 1.1em;
}

.div-auto-weight input {
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  .inside-header {
    width: 805px;
  }

  .btn-save button {
    width: 150px;
  }

  .exports button {
    width: 95px;
  }

  p {
    font-size: 11px;
  }

  .right,
  .left {
    min-width: 150px;
  }
}

.tab-header {
  display: none;
}

@media print {

  .btn-save,
  .exports {
    display: none;
  }
}
</style>
