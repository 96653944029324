<template>
  <div class="user-header-container" v-if="user.length > 0">
    <div class="user-left-header">
      <div class="container-photo" ref="internal">
        <div :class="matrix == true ? 'user-header-photo' : 'user-header-photo-teams'">
          <img v-if="src !== false && src !== null && error == false" :src="src" @error="error = true" class="cover" />
          <i class="fas fa-user icon" aria-hidden="true" v-else></i>
        </div>
      </div>
      <div class="user-header-data">
        <span ref="user-name" class="user-header-name">{{ user[0].professional_name }}</span>

        <span ref="job" class="purple job-span line-clamp">{{ user[0].user_job ?? '[sem função]' }}</span>

        <span ref="header-teams" class="purple team-span line-clamp">
          <span v-if="user[0].teams.length == 0">[sem equipa]</span>
          <span v-else-if="user[0].teams.length == 1" v-for="team in user[0].teams" :key="team">{{ team }}</span>
          <span v-else>{{ user[0].teams.length }} equipas</span>
        </span>
      </div>
    </div>

    <div class="user-right-header"
      v-if="typeof MatrixEvaluation != 'undefined' && matrix == true && MatrixEvaluation[GetEvaluationSelected]">
      <div class="top-div">
        <p>Avaliação atual</p>
      </div>

      <div class="mid-div"
        :class="{ 'without-result-row': HasNullVolume || (EntityHasNullVolume && GetEvaluationSelected != 2) }">
        <p>{{ Gems.FormatDecimals(MatrixEvaluation[GetEvaluationSelected][0], 2) }}</p>
        <p class="percent">%</p>
      </div>

      <div class="div-quali"
        :class="{ 'without-result-row': HasNullVolume || (EntityHasNullVolume && GetEvaluationSelected != 2) }">
        <p>{{ HasNullVolume || (EntityHasNullVolume && GetEvaluationSelected != 2) ? '[Provisório]' :
          MatrixEvaluation[GetEvaluationSelected][1] }}</p>
      </div>

    </div>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { mapGetters } from "vuex";
import { Gems } from "../gems";

export default {
  name: "UserHeader",
  props: {
    userId: [String, Number],
    matrix: Boolean,
    userEvaluation: Number,
  },
  data() {
    return {
      src: String,
      user: [],
      error: false,
      Gems,
    };
  },
  methods: {
    async GetUserHeader(userId) {
      await KPI.CallBackEnd("get", "get-user", {
        params: { id: userId, cycle: localStorage.getItem("cycle") },
      })
        .then((res) => {
          this.user = res.data[0]
          this.src = this.user[0]["src"]
        })
    },

    // ...mapActions(["GetEvaluation"]),

    handleScroll() {
      const divHeader = document.getElementById("myHeader")
      const size = 10

      if (divHeader) {
        var sticky = divHeader.offsetTop;

        if (document.body.scrollTop > sticky)
          divHeader.classList.add("sticky")

        if (document.body.scrollTop < size)
          divHeader.classList.remove("sticky")
      }
    },

    Tooltips() {
      if (this.$refs["header-teams"]) {
        let teams = this.user[0].teams.slice().toString()
        Gems.Tooltip(this.$refs["header-teams"], `Equipa(s): \r\n\r\n${teams.replaceAll(",", "\n")}`, "tooltip tooltip-left")
      }

      if (this.$refs["internal"])
        Gems.Tooltip(this.$refs["internal"], `Nº ${this.user[0].user_internal}`, "tooltip")

      if (this.$refs["user-name"])
        Gems.Tooltip(this.$refs["user-name"], this.user[0]["complete_name"], "tooltip")

      if (this.$refs["job"])
        Gems.Tooltip(this.$refs["job"], `Função: \r\n\r\n${this.user[0]["user_job"]}`, "tooltip tooltip-left")
    }
  },
  computed: {
    ...mapGetters(["MatrixEvaluation", "GetEvaluationSelected", "HasNullVolume", "EntityHasNullVolume"]),
  },
  mounted() {
    this.GetUserHeader(this.userId);
    window.addEventListener("scroll", this.handleScroll, true);
    this.Tooltips()
  },
  updated() {
    this.Tooltips()
  },
  beforeUnmount() {
    window.removeEventListener('scroll', window)
  }
};
</script>

<style scoped>
.user-header-container {
  display: flex;
  justify-content: space-between;
  height: 120px;
  background-color: white;
  -webkit-box-shadow: 0px 3px 10px -5px rgb(0 0 0 / 70%);
  -moz-box-shadow: 0px 3px 10px -5px rgb(0 0 0 / 70%);
  box-shadow: 0px 3px 10px -5px rgb(0 0 0 / 70%);
  margin-bottom: 10px;
}

.user-header-container div {
  display: flex;
}

.user-left-header {
  padding-left: 20px;
}

.user-right-header {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  width: 100px;
  margin: auto 0 auto 0;
}

.user-right-header div {
  justify-content: center;
  flex: 1;
}

.user-right-header p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.user-header-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.user-header-name {
  cursor: default;
  margin-top: -2px;
  font-size: 23px;
}

.job-span {
  margin-top: 2px;
}

.team-span {
  margin-top: 5px;
  margin-bottom: 0px !important;
}

.purple {
  color: var(--second-app-color);
  font-size: 16px;
  cursor: default;
}

.user-header-photo,
.user-header-photo-teams {
  width: 90px;
  height: 90px;
  background-color: var(--light-gray-color);
  border: 1px solid white;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.user-header-photo img,
.user-header-photo-teams img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: 0 auto;
}

.user-header-photo .icon,
.user-header-photo-teams .icon {
  display: flex;
  align-self: center;
  margin: 0 auto 5px auto;
  font-size: 5em;
}

.top-div {
  text-align: center;
}

.mid-div {
  font-size: 35px;
}

.percent {
  font-size: 18px;
  margin-top: 18px !important;
  margin-left: 3px !important;
}

.cover {
  object-fit: cover;
  width: 150px;
  height: 100px;
}

.container-photo {
  display: flex;
  flex-direction: column;
  margin: 14px 0;
}

.div-quali {
  font-size: 1.3rem;
  color: #a976fd;
}
</style>
