<template>
  <div>

    <div class="div-video-container" style="display: none;">
      <p @click="toggleVideoContainer">Vídeos</p>
      <i class="fas fa-film" @click="toggleVideoContainer"></i>

      <div v-show="showVideoList" class="div-video-list-container tooltip">
        <div class="div-video-list">
          <span @click="selectVideo(1)">Escolher e mudar de ciclo de avaliação</span>
          <!--<span @click="selectVideo(2)">Utilizar a Carta 360º</span>-->
        </div>
        <div class="div-btn-close">
          <i class="fas fa-times" @click="toggleVideoContainer"></i>
        </div>
      </div>
    </div>

    <div v-show="showVideoPlayer" id="div-video-player-container" class="div-modal">
      <video id="video" controls ref="videoPlayer">
        <source :src="selectedVideo" type="video/mp4" />
      </video>
      <div class="div-close-video">
        <i class="far fa-times-circle fa-2x" @click="closeVideo"></i>
      </div>
    </div>
    
    <div class="div-help-container">
      <!--
      <div class="div-help-section">
        <br>
        <p>
        </p>
      </div>
      -->
      <br>
      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Cirvalue KPI</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <p>
            O Cirvalue KPI é um dos três sistemas de Avaliação do Desempenho da família de ferramentas 
            Cirvalue (KPI, COMP e 360).
          </p>
          <p>
            A sua Empresa pode ter escolhido apenas este sistema, ou qualquer combinação de dois dos sistemas 
            disponíveis ou mesmo os três, uma vez que se articulam e completam.
          </p>
          <p>
            Caso seja utilizado mais do que um dos sistemas Cirvalue, o Cirvalue KPI será também a ferramenta 
            que permite a integração de todos os sistemas adotados, traduzida na apresentação de um 
            resultado ponderado agregado.
          </p>
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>O que é o Cirvalue KPI?</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <p>
            O Cirvalue KPI é especificamente um sistema de Avaliação de Desempenho, baseado em indicadores-chave 
            de performance.
          </p>
          <p>
            Através da matriz de avaliação de desempenho que lhe será apresentada, poderá tomar conhecimento dos 
            indicadores que compõem a sua avaliação de desempenho, dos pesos que assumem na mesma, dos objetivos 
            pretendidos e dos resultados que vão sendo alcançados, traduzindo-se em resultados avaliativos.
          </p>
          <p>
            Tais indicadores dizem respeito à sua Empresa como um todo, à equipa que integra e a si individualmente, 
            tanto nas componentes de resultados como nas de competências e comportamentos profissionais, caso o 
            Cirvalue COMP e/ou o 360 tenham sido também contratados.
          </p>
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Como começar?</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <p>
            Escolher o Ciclo de Avaliação que estiver ativo, ou escolher o correto se estiver mais do que um ativo.
          </p>
          <img src="../images/01_kpi_help.jpg" alt="help-image" />
          <p>
            Depois de escolher um Ciclo de Avaliação, o sistema encaminhá-lo-á automaticamente para a sua Matriz de Desempenho.
          </p>
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Matriz de Desempenho</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <img src="../images/02_kpi_help.jpg" alt="help-image" />
          <p>
            Na Matriz, tem uma visão geral das suas avaliações, bem como da Empresa, da sua equipa e ainda do Cirvalue COMP e 360.
          </p>
          <p>
            Para cada um deles, pode ver todas as informações disponíveis para os indicadores escolhidos pela Empresa.
          </p>
          <p>
            Pode visualizar, para cada indicador:
          </p>
          <ul>
            <li>O seu Peso;</li>
            <li>A Métrica, que pode ser Linear Direta, onde quanto mais alto o valor, melhor, ou Linear Inversa, onde quanto mais 
              baixo o valor atingido, melhor o resultado;</li>
            <li>A Unidade em que esse indicador é medido;</li>
            <li>O Objetivo proposto;</li>
            <li>O Valor Crítico, que representa o valor mínimo aceitável para esse indicador;</li>
            <li>O seu Resultado;</li>
            <li>A percentagem de Realização, que se refere ao seu resultado face ao objetivo proposto;</li>
            <li>A percentagem da sua Avaliação para esse indicador.</li>
          </ul>
          <p>
            Pode também visualizar o Manómetro e, ao clicar nele, terá uma perceção mais visual da sua realização e avaliação 
            para esse indicador.
          </p>
          <img src="../images/03_kpi_help.jpg" alt="help-image" />
          <p>
            A barra vermelha no manómetro de Realização representa o ponto crítico para esse indicador.
          </p>
          <p>
            No manómetro da Avaliação, pode ver onde se encontra na escala de avaliação para esse indicador.
          </p>
          <p>
            Por fim, tem os comentários, onde, ao selecionar, pode visualizar os comentários deixados por si ou 
            pelos seus superiores hierárquicos sobre esse indicador.
          </p>
          <img src="../images/04_kpi_help.jpg" alt="help-image" />
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Opção de apuramento</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <p>
            As opções de apuramento dizem respeito apenas à forma como os resultados da Empresa são 
            contabilizados no resultado agregado da Avaliação de Desempenho individual.
          </p>
          <p>
            Existem três opções de apuramento, mas apenas verá aquelas que a sua Empresa tenha adotado 
            no ciclo de avaliação.
          </p>
          <img src="../images/05_kpi_help.jpg" alt="help-image" />
          <p>
            Se tiver mais do que uma opção de apuramento disponível, repare que o valor agregado da sua 
            avaliação se altera quando muda de opção. Repare que também o peso real de cada bloco se 
            altera quando muda de opção.
          </p>
          <p>
            <strong>Apuramento Integrado</strong>
          </p>
          <p>
            Nesta opção, o resultado avaliativo da Empresa assume um peso real na sua avaliação de desempenho.
          </p>
          <p>
            <strong>Apuramento Específico</strong>
          </p>
          <p>
            Nesta opção, o resultado avaliativo da Empresa é visualizado, mas não conta para a sua avaliação de desempenho.
          </p>
          <p>
            <strong>Apuramento Ponderado</strong>
          </p>
          <p>
            Nesta opção, a sua avaliação específica de desempenho é multiplicada pelo valor de avaliação da Empresa.
          </p>
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Equipas</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <p>
            Aqui consegue ver a sua equipa e, se for o caso, as pessoas que se encontrem abaixo na hierarquia.
          </p>
          <p>
            Se for líder da equipa ou superior hierárquico, pode consultar as matrizes de cada um, selecionando o 
            respetivo ícone de manómetro.
          </p>
          <img src="../images/06_kpi_help.jpg" alt="help-image" />
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Utilizadores</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <p>
            Aqui apenas consegue ver os seus subordinados ou utilizadores que se encontrem abaixo na hierarquia, 
            podendo consultar a matriz, equipa e a hierarquia abaixo de cada um deles, carregando nos ícones 
            indicados na imagem.
          </p>
          <img src="../images/07_kpi_help.jpg" alt="help-image" />
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Síntese</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <p>
            Aqui tem acesso a uma síntese, atualizada ao momento, de todas as suas avaliações, tanto no KPI 
            como no COMP e/ou 360, caso se aplique.
          </p>
          <img src="../images/08_kpi_help.jpg" alt="help-image" />
          <p>
            Inclui ainda, sob a designação "Informações e Impactos", mensagens personalizadas que o Admin 
            queira dirigir especificamente a cada avaliador, como, por exemplo, informações sobre prémios 
            atribuídos.
          </p>
          <p>
            Em "Prioridades de Melhoria" figurarão os indicadores ou competências cujos baixos resultados 
            avaliativos recomendem atenção e necessidade de melhorar. Pode não haver caso todos os resultados 
            sejam suficientemente positivos.
          </p>
          <p>
            Finalmente, poderá ainda escrever comentários, objetivos e compromissos, que, ao gravá-los, ficarão 
            na zona inferior da página, com a data em que foram criados. Pode apagá-los quando quiser. Estarão 
            também visíveis eventuais comentários que as suas chefias possam ter escrito na sua síntese.
          </p>
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Relatório</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <p>
            Se for chefia ou administrador, terá ainda acesso a um relatório com o ranking das avaliações da(s) 
            equipa(s) que lhe reportem, e ainda ao ranking geral de todos os seus subordinados diretos ou indiretos.
          </p>
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Informação sobre o sistema de privacidade</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <div class="div-help div-last-help">
            <img src="../images/09_kpi_help.jpg" alt="help-image" />
              <p>
                O menu disponível permite ainda consultar o texto sobre Privacidade e Proteção de Dados Pessoais.
              <br><br>
                Neste menu, também pode dar ou retirar a autorização para que a sua fotografia apareça.
              </p>
          </div>
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Sair do sistema</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <div class="div-help div-last-help">
            <img src="../images/10_kpi_help.jpg" alt="help-image" />
              <p>
                A opção “SAIR” permite abandonar a aplicação, fazendo também ‘logout’ do inPeopleHub.
              </p>
          </div>
        </div>
      </div>

      <div class="div-help-section">
        <div class="div-show-content" @click="toggleExtraInfo">
          <label>Navegar para outros sistemas</label>
          <i class="fas fa-chevron-down"></i>
        </div>
        <div class="div-content" style="display: none;">
          <div class="div-help div-last-help">
            <img src="../images/11_kpi_help.jpg" alt="help-image" />
              <p>
                Os atalhos no final do menu principal (abaixo do logótipo da empresa) dão acesso direto a outros 
                sistemas Cirvalue que a sua empresa tenha contratado.
              </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showVideoList: false,
      showVideoPlayer: false,
      selectedVideo: "",
    };
  },
  methods: {
    toggleVideoContainer() {
      if (this.showVideoPlayer) {
        this.closeVideo();
      }
      this.showVideoList = !this.showVideoList;
    },
    selectVideo(videoId) {
      const videos = {
        1: "/docs/help-1.mp4",
        /*2: "/docs/help-2.mp4",*/
      };
      this.selectedVideo = videos[videoId] || "";
      this.showVideoPlayer = true;
      this.$nextTick(() => {
        if (this.$refs.videoPlayer) {
          this.$refs.videoPlayer.load();
          this.$refs.videoPlayer.play();
        }
      });
    },
    closeVideo() {
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.currentTime = 0;
      }
      this.showVideoPlayer = false;
      this.selectedVideo = "";
    },
    toggleExtraInfo(event) {
      const contentDiv = event.currentTarget.nextElementSibling;
      if (contentDiv) {
        contentDiv.style.display = contentDiv.style.display === "none" ? "block" : "none";
      }
    },
  },
};
</script>

<style scoped>
.div-video-container {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

[control-video-container] {
  cursor: pointer;
}

.fa-film {
  transform: scale(1.3);
  cursor: pointer;
}

.div-help {
  display: grid;
  grid-template-columns: 40% 55%;
  place-content: space-between;
}

.div-last-help {
  grid-template-columns: 23% 73%;
}

.div-help p:first-child {
  margin: 0;
}

img {
  width: 100%;
}

.div-video-list-container {
  display: flex;
  justify-content: space-around;
  font-size: 0.8em !important;
  padding: 17px 15px !important;
  z-index: 99 !important;
  width: 350px !important;
  right: 55px;
  top: 60px;
}

.div-video-list-container span {
  width: fit-content;
  cursor: pointer;
  line-height: 15px;
}

.fa-times-circle,
.fa-times {
  cursor: pointer;
  transform: scale(1.1);
}

.fa-times-circle:hover,
.fa-times:hover {
  color: var(--default-app-color);
}

.div-btn-close {
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  transform: scale(1.4);
  margin: -5px -12px 0 0;
}

.div-btn-close i {
  color: black;
  align-self: baseline;
  font-size: 10px;
}

.div-video-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.div-video-list span:hover {
  color: var(--default-app-color);
}

#video {
  position: relative;
  width: 70%;
}

#div-video-player-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-modal {
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.5);
  /* Black w/ opacity */
}

.div-close-video {
  position: absolute;
  right: 12.4%;
  top: 6.5%;
}

.div-close-video i {
  color: var(--default-gray-color);
}

.tdMain a {
  cursor: pointer;
  color: var(--default-app-color);
}

.fa-chevron-down,
.fa-chevron-up {
  cursor: pointer;
}

.div-help-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.div-show-content {
  display: flex;
  background-color: var(--medium-gray-color);
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 30px;
  padding: 4px 15px 4px 12px;
  width: 97%;
}

.div-show-content label {
  cursor: pointer;
  user-select: none;
}

.div-content {
  padding: 20px 10px 0 10px;
}

.div-content img {
  margin-block-start: 1em;
  margin-block-end: 1em;
}


@media screen and (max-width: 1370px) {
  .div-close-video {
    top: 10.2%;
    right: 11.5%;
  }
}

@media screen and (min-width: 2500px) {
  .div-close-video {
    right: 27.8%;
    top: 19.2%;
  }

  .div-close-video i {
    font-size: 3em;
  }

  #video {
    width: 40%;
  }
}
</style>
