<template>
  <div class="page-content">
    <div v-if="openTeam == false">
      <div class="tab">
        <div class="tab-left">
          <div class="tablinks models-tab" @click="ChangeCards($event, 'Models', 0)" id="models">
            MODELOS
          </div>

          <div class="tablinks users-tab" @click="ChangeCards($event, 'Users', 0)" id="users">
            <div>
              UTILIZADORES
              <div ref="toggle" class="show-all" v-show="options === 'Users' || users === true">
                <input type="checkbox" class="cursor-pointer" id="switch" @click="SwitchToggle" v-model="toggle" />
                <label class="cursor-pointer" for="switch">Mostrar inativos</label>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-right">
          <div class="tablinks" @click="ChangeCards($event, 'Functions', 1)" id="functions">
            FUNÇÕES
          </div>

          <div class="tablinks" @click="ChangeCards($event, 'Teams', 1)" id="teams">
            EQUIPAS
          </div>

          <div class="tablinks options-tab" @click.stop="ShowOptions()">
            <input type="checkbox" class="chk-options cursor-pointer" v-model="show"/>
            <label class="cursor-pointer">Opções</label>
          </div>
        </div>
      </div>

      <table>
        <tr>
          <td class="tdUsers">
            <div class="divUsers scrollbar">
              <div class="tabcontent" id="Users">
                <UsersFilter
                  :draggable="true"
                  :updateTeamsUser="updateTeamsUser"
                  :toggle="toggle"
                />
              </div>

              <div class="tabcontent" id="Models">
                <TemplatesPreviewList
                  :arrTemplates="arrTemplates"
                  :draggable="true"
                />
              </div>
            </div>
          </td>

          <td class="tdTeams">
            <div class="divTeams scrollbar" id="right-side-container">
              <div class="div-options" v-show="show === true">
                <div class="input" v-show="options !== 'Functions' && create === true">

                  <div class="div-show-all-teams">
                    <i :class="showAll ? 'far fa-caret-square-up' : 'far fa-caret-square-down'" ref="showAllTeams"
                      class="icon fa-2x cursor-pointer" aria-hidden="true" @click="ToggleCollapseTeams"></i>
                  </div>

                  <input
                    type="text"
                    v-model="txtTeamInternal"
                    placeholder="Código da equipa..."
                    ref="internalTxt"
                    class="new-team-code"
                  />
                  
                  <input
                    type="text"
                    v-model="txtTeamName"
                    placeholder="Nome da equipa..."
                    ref="nameTxt"
                    class="new-team"
                  />
                  <button class="btn-action new-team-btn" @click="ConfirmCreateTeam()">
                    CRIAR EQUIPA
                  </button>
                </div>
                <div class="radios">
                  <div v-show="create === false">
                    <p>Substituir modelos já atribuidos?</p>
                    <div class="radios-inputs">
                      <input type="radio" @click="func = !func" :checked="func == true" />
                      <p>Sim</p>
                      <input type="radio" @click="func = !func" :checked="func == false" />
                      <p>Não</p>
                    </div>
                  </div>
                  <div v-show="options === 'Models' || users === false">
                    <p>Manter os resultados existentes?</p>
                    <div class="radios-inputs">
                      <input type="radio" @click="volume = !volume" :checked="volume == true" :disabled="func == false && functions" />
                      <p>Sim</p>
                      <input type="radio" @click="volume = !volume" :checked="volume == false" :disabled="func == false && functions" />
                      <p>Não</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tabcontent-right" id="Teams">
                <div
                  class="divTeam"
                  :key="team.team_id"
                  v-for="team in arrTeams"
                >
                  <TeamBlock
                    :objTeam="team"
                    @update-teams="UpdateTeams(false)"
                    @open-team="OpenTeam($event)"
                    :card="card"
                    :volume="volume"
                  />
                </div>
              </div>
              <div class="tabcontent-right" id="Functions">
                <div
                  class="divTeam"
                  :key="functions.func_id"
                  v-for="functions in arrFunc"
                >
                  <FunctionsBlock
                    @update-teams="UpdateTeams(true)"
                    :objFunc="functions"
                    :card="card"
                    :volume="volume"
                    :func="func"
                  />
                </div>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div v-else>
      <div v-if="arrTeamsIndicators[0] != undefined" class="scope">
        <div class="header">
          <i
            class="fas fa-arrow-circle-left icon fa-2x cursor-pointer"
            @click="BackTeam()"
            aria-hidden="true"
          ></i>
          <div class="divTitle">
            <input type="text" v-model="teamName" class="team-title" />
          </div>
        </div>
        <SearchIndicator :templateID="teamId" class="search" :team="true" />
        <br />
        <table class="scopeRow sco_color_2">
          <tr>
            <td class="tdIcon">
              <i class="fas fa-users icon fa-2x" aria-hidden="true"></i>
            </td>
            <td class="tdDesig">
              <div class="divDesig">Equipa</div>
              <div>
                Indicadores: {{ arrTeamsIndicators[0]["INDICATORS"].length }}
              </div>
            </td>
          </tr>
        </table>
        <TeamsIndicatorList
          :arrIndicators="arrTeamsIndicators[0]['INDICATORS']"
          class="tblIndicators"
        />
      </div>
      <div class="div-buttons">
        <button @click="SaveTeam(teamName)">
          GRAVAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import UsersFilter from "../components/UsersFilter";
import TeamBlock from "../components/TeamBlock.vue";
import FunctionsBlock from "../components/FunctionsBlock.vue";
import TemplatesPreviewList from "../components/TemplatesPreviewList";
import TeamsIndicatorList from "../components/TeamsIndicatorList";
import SearchIndicator from "../components/SearchIndicator";
import { mapGetters, mapActions } from "vuex";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "teams",
  components: {
    UsersFilter,
    TeamBlock,
    TemplatesPreviewList,
    FunctionsBlock,
    TeamsIndicatorList,
    SearchIndicator,
  },
  data() {
    return {
      arrTeams: [],
      show: false,
      updateTeamsUser: false,
      arrFunc: [],
      arrTemplates: [],
      card: String,
      options: String,
      volume: true,
      func: false,
      create: Boolean,
      users: Boolean,
      openTeam: false,
      openTeamArr: [],
      teamId: Number,
      toggle: false,
      teamName: String,
      txtTeamName: "",
      txtTeamInternal: "",
      functions: false,
      showAll: false
    };
  },
  methods: {
    ...mapActions(["FetchTeams", "SaveTeam"]),

    ShowOptions(){
      if(!this.show) 
        document.getElementById("right-side-container").scrollTop = 0
      
      this.show = !this.show
    },

    SwitchToggle() {
      this.toggle = !this.toggle;
    },

    BackTeam() {
      this.openTeam = !this.openTeam;
      setTimeout(function () {
        document.getElementById("users").click();
      }, 1000);
    },

    async GetTeams() {
      this.arrTeams = []
      const response = await KPI.CallBackEnd("get", "get-teams", {
        params: { cycle: localStorage.getItem("cycle") },
      })

      if (response.status != 200) {
        Gems.Telegram(KPI.ERROR_MESSAGE_GENERIC, "tele-not-allowed")
        return
      }

      this.arrTeams = response.data
    },

    async ConfirmCreateTeam() {
      if (this.txtTeamName.trim() == ''|| this.txtTeamInternal.trim() == '')  {
        Gems.Telegram('Preencha os campos', 'tele-not-allowed')
        return
      }

      for (let team in this.arrTeams) {
        if (this.arrTeams[team]["team_desig"].toLowerCase() == this.txtTeamName.toLowerCase()) {
          Gems.Telegram("Já existe uma equipa com este nome", "tele-not-allowed")
          return
        }

        if (this.arrTeams[team]["team_internal"] == this.txtTeamInternal) {
          Gems.Telegram("Já existe uma equipa com este código", "tele-not-allowed")
          return
        }
      }

      const team_name = this.txtTeamName;
      await Gems.Telegram(`Criar uma nova equipa '${team_name}'?`, "tele-info", "", "").then(
        () => { this.CreateTeam() },
        () => {}
      );
    },

    async CreateTeam() {
      const team_name = this.txtTeamName;
      const team_internal = this.txtTeamInternal;
      const response = await KPI.CallBackEnd("post", "set-operations", {
        params: {
          set: "create-team",
          team_name,
          team_internal
        },
      })

      if (response.status != 200) {
        Gems.Telegram(KPI.ERROR_MESSAGE_GENERIC, "tele-not-allowed")
        return
      }

      this.GetTeams()
      Gems.Telegram("Operação realizada com sucesso!", "tele-success")
      this.txtTeamName = ''
      this.txtTeamInternal = ''
    },

    OpenTeam(team) {
      this.teamId = team.team_id;
      this.teamName = team.team_desig;
      this.FetchTeams(team.team_id);
      this.openTeam = !this.openTeam;
    },

    GetColor() {
      return `${this.$refs.color.getAttribute("class")} opened-team`;
    },

    UpdateTeams(updateTeam) {
      if(updateTeam)
        this.GetTeams()
      else
        this.GetFunctions()

      this.updateTeamsUser = !this.updateTeamsUser
    },

    async GetTemplates() {
      const response = await KPI.CallBackEnd("get", "get-templates", {
        params: { cycle_id: localStorage.getItem("cycle") },
      })
      
      this.arrTemplates = response.data
    },

    ChangeCards(evt, cardName, type) {
      this.functions = false;

      let tablinks = document.getElementsByClassName("tablinks");
      Array.from(tablinks).forEach(tab => {
        if(cardName == 'Teams') {
          if(tab.id != 'models' && tab.id != 'users')
            tab.className = tab.className.replace(" active", "")
        } else if (cardName == 'Models') {
          if(tab.id != 'teams' && tab.id != 'functions')
            tab.className = tab.className.replace(" active", "")
        } else {
          tab.className = tab.className.replace(" active", "")
        }
      })

      if (cardName == "Functions") {
        this.create = false;
        this.users = false;
        this.functions = true;
        document.getElementById("models").click();
      } 
      
      if (cardName == "Users") {
        this.card = cardName;
        this.options = cardName;
        this.users = true;
        document.getElementById("teams").click();
      } 
      
      if (cardName == "Teams") {
        this.create = true;
      }

      if (cardName == "Models") {
        this.card = cardName;
        this.options = cardName;
        this.users = false;
      }

      var i, tabcontent;

      if (type === 0) {
        tabcontent = document.getElementsByClassName("tabcontent");
      } else {
        tabcontent = document.getElementsByClassName("tabcontent-right");
      }

      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      document.getElementById(cardName).style.display = "block";
      evt.currentTarget.className += " active";
    },

    async GetFunctions() {
      this.arrFunc = []
      const response = await KPI.CallBackEnd("get", "get-operations", {
        params: { get: "functions", cycle: localStorage.getItem("cycle") },
      })
    
      this.arrFunc = response.data
    },

    ToggleCollapseTeams () {
      this.showAll = !this.showAll
      document.querySelectorAll('#show-team').forEach(elem => {
        if(this.showAll && elem.classList.contains('fa-sort-down'))
          elem.click()

        if(!this.showAll && elem.classList.contains('fa-sort-up'))
          elem.click()
      })
    },

    SetPageTooltips() {
      if(this.$refs.internalTxt)
        Gems.Tooltip(this.$refs.internalTxt, "Código da equipa", "tooltip")

      if(this.$refs.nameTxt)
        Gems.Tooltip(this.$refs.nameTxt, "Nome da equipa", "tooltip")

      if(this.$refs.showAllTeams)
        Gems.Tooltip(this.$refs.showAllTeams, "Abrir/Fechar todas", "tooltip")

      if (this.$refs.toggle) 
        Gems.Tooltip(this.$refs.toggle, "Marque para ver os utilizadores inativos", "tooltip")
    }
  },
  computed: {
    ...mapGetters(["arrTeamsIndicators"]),
  },
  mounted() {
    this.GetTeams()
    this.GetTemplates()
    this.GetFunctions()
    this.SetPageTooltips()
    
    
    document.getElementById("users").click()
  },
  updated() {
    this.SetPageTooltips()
  },
};
</script>

<style>
  .card {
    width: 160px;
  }

@media only screen and (max-width: 1400px) {
  .card {
    width: 129px;
  }
}
</style>

<style scoped>
@import '../assets/matrix.css';

.page-content {
  margin-top: -10px;
}

table {
  width: 100.5%;
  height: 94vh;
  border-collapse: collapse;
}

.scope {
  margin-left: 10px;
  margin-right: 10px;
}

.radios {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.radios div {
  display: flex;
  justify-content: space-between;
}

.radios p {
  margin: 5px 0px;
}

.radios-inputs {
  text-align: center;
}

.radios input {
  padding-top: 0px;
  margin: 0px 3px;
}

.input {
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.input input {
  width: 66%;
}

.tdUsers {
  vertical-align: top;
  width: 55%;
}

.tdTeams {
  vertical-align: top;
  width: 45%;
}

.div-options {
  margin: 5px 10px 0px 10px;
}

.divTeams,
.divUsers {
  height: 100%;
  overflow-y: scroll;
}

.divTeam {
  text-align: center;
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(30, 60, 90, 0.3);
}

.tab {
  overflow: hidden;
  display: flex;
}

.tab-left,
.tab-right {
  display: flex;
}

.tab-left {
  width: 55%;
}

.tab-right {
  width: 45%;
}

.tablinks {
  flex: 2;
  padding: 10px;
  float: left;
  border-radius: 0px;
  color: black;
  border: 1px solid transparent;
  border-bottom: 1px solid black;
  text-align: center;
  cursor: pointer;
  background-color: var(--medium-gray-color);
  opacity: 0.4;
}

.options-tab {
  display: flex;
  justify-content: center;
  flex: 1;
  opacity: 1;
  background-color: var(--light-gray-color);
  border-bottom: 1px solid #999999;
}

.tablinks.active {
  border: 1px solid black;
  border-bottom: 1px solid white;
  opacity: 1 !important;
  background-color: white !important;
  color: var(--default-app-color);
}

.tabcontent {
  display: none;
  border-top: none;
}

.tabcontent-right {
  display: none;
  border-top: none;
}


.scopeRow {
  width: 100%;
  height: 60px;
  border-spacing: 0px;
  color: var(--light-gray-color);
}

.tblIndicators {
  width: 100%;
  height: 17px;
}

.tdIcon {
  width: 5%;
  text-align: center;
}

.tdDesig {
  width: 20%;
  padding: 5px;
}

.divDesig {
  font-size: large;
  font-weight: bold;
  color: var(--light-gray-color);
}

.div-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  float: right;
}

.div-buttons button {
  width: 300px;
  font-size: 1.25em;
}

.team-title {
  font-size: 1.725em;
  border: none;
}

.show-all {
  display: flex;
  align-items: center;
}

.show-all label {
  font-weight: 500;
}

#switch {
  transform: scale(1.5);
  margin: 0;
  margin-right: 15px !important;
}

.divTitle {
  font-size: 25pt;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
}

.header {
  display: flex;
  align-items: center;
}

.new-team {
  width: 61% !important;
  margin: 0px 8px 0 0;
}

.new-team-code {
  width: 20% !important;
  margin: 0px 8px 0 0;
}

.new-team-btn {
  width: 152px !important;
  height: fit-content;
  padding: 6px;
}

.div-show-all-teams {
  margin-right: 10px;
  margin-top: 1.5px;
  align-self: center;
}

.div-show-all-teams i:hover {
  color: var(--default-app-color);
}

.fa-sort-up {
  transform: translateY(5px);
}

.fa-sort-down {
  transform: translateY(-5px);
}

.chk-options {
  margin-right: 5px;
}

.users-tab,
.models-tab {
  flex: 2;
}

.users-tab div {
  display: flex;
  justify-content: space-evenly;
}

@media only screen and (max-width: 1400px) {

  .tabcontent {
    width: 456px;
  }

  .new-team {
    width: 50% !important;
  }
}
</style>
