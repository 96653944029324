<template>
  <div
    :id="objTeam.team_id"
    @drop.stop="DropTemplate(null, $event, 0)"
    @dragover.prevent
    @dragenter.prevent
    @dragleave.prevent
    v-if="show !== true"
  >
    <div class="dropdown">
      <div class="icon-div left cursor-pointer">
        <i
          :class="minimize == false ? 'fas fa-sort-up' : 'fas fa-sort-down'"
          class="icon fa-2x"
          aria-hidden="true"
          @click="minimize = !minimize"
          id="show-team"
        ></i>
      </div>

      <p
        class="team-title open-team cursor-pointer"
        @drop.stop="DropTemplate(null, $event, 1)"
        @dragenter="Enter($event, 0)"
        @dragleave="Leave($event, 0)"
        @click="$emit('open-team', objTeam)"
        ref="teamTitle"
        @mouseenter="Gems.Tooltip($refs.teamTitle, objTeam.team_internal, 'tooltip')"
      >
        {{ objTeam.team_desig }}
      </p>

      <div class="icon-div right cursor-pointer">
        <i
          class="fas fa-trash-alt"
          aria-hidden="true"
          @click="ConfirmDeleteTeam(objTeam)"
          ref="deleteTeam"
        ></i>
      </div>
    </div>
    <table class="person" v-show="minimize == false">
      <thead>
        <tr class="person-header">
          <td>Líder</td>
          <td></td>
          <td>Modelo</td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody @dragenter="ChangePointer()" @dragleave="ChangePointer()">
        <tr
          class="divUsersWrapper"
          id="tr"
          :key="user.user_id"
          :team="user.team_id"
          v-for="(user, index) in objFormation"
          @dragleave.capture="Leave($event, user)"
        >
          <td
            :id="'divUser' + objTeam.team_id + '_' + user.user_id"
            class="divUser"
            :class="user.was_on_team == 1 ? 'user-removed' : ''"
            
            @drop.stop="DropTemplate(user.user_id, $event, 0)"
            @dragover="Enter($event, user)"
            v-if="typeof user == 'object' && user.user_id != null"
          >
            <UserBlock
              :objTeamUser="user"
              :index="index"
              :teamUser="true"
              @exclude="DecreaseTeam($event)"
              @exclude-template="RemoveTemplate($event)"
              @LeaderChecked="LeaderChecked($event)"
              :event="event"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import UserBlock from "./UserBlock.vue";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "TeamBlock",
  props: {
    objTeam: Object,
    volume: Boolean,
    card: String,
    show: Boolean,
  },
  emits: ["open-matrix", "open-team", "update-teams"],
  components: {
    UserBlock,
  },
  data() {
    return {
      objFormation: Object,
      arrTemplates: Array,
      usersId: [],
      isOpen: false,
      event: false,
      minimize: true,
      Gems
    };
  },
  methods: {
    ConfirmDeleteTeam(team) {
      Gems.Telegram(
        `Apagar a equipa '${team.team_desig}'? (As equipas apagadas permanecem visíveis nos ciclos em que houverem resultados registrados)`,
        "tele-info",
        "",
        ""
      ).then(
        () => {
          KPI.CallBackEnd("post", "delete-team", { team: team.team_id })
            .then((response) => {
              if (response.data.error == false) 
                Gems.Telegram("Apagado com sucesso", "tele-success")
              else 
                Gems.Telegram(response.data.message, "tele-not-allowed")
              
              this.$emit("update-teams", event);
            })
        },
        () => {}
      );
    },

    Matrix(user) {
      if (user.has_matrix > 0) 
        this.$emit("open-matrix", user)
    },

    DropTemplate(user, event, flag) {
      this.usersId = [];
      if (this.card === "Users") {
        this.IncreaseTeam(event)
        return
      } 
              
      for (let user in this.objFormation) 
        document.getElementById(`divUser${this.objTeam.team_id}_${this.objFormation[user].user_id}`).classList.remove("dropzone")

      if (flag === 1) {
        for (let user of this.objFormation) {
          this.usersId.push(user.user_id)
        }
      } else {
        this.usersId.push(user)
      }

      if ((flag != 1 && user != null) || flag == 1) 
        this.SetTemplate(JSON.parse(event.dataTransfer.getData("template")))
    },

    async IncreaseTeam(event) {
      const user = JSON.parse(event.dataTransfer.getData("user"))
      let exists = false

      this.objFormation.forEach(row => {
        if(user.user_id == row.user_id && row.was_on_team == 0)
          exists = true
      })

      if (exists) {
        Gems.Telegram(`${user.professional_name} já está na equipa`, "tele-not-allowed")
        return
      }

      Object.assign(this.objFormation, {
        user_id: user.user_id,
        professional_name: user.professional_name,
        complete_name: user.complete_name,
        user_job: user.user_job,
        user_template: user.user_template,
        leader: 0,
      })

      KPI.CallBackEnd("post", "increase-team", {
        newUser: {
          team_id: this.objTeam.team_id,
          teammate_id: user.user_id,
          cycle: localStorage.getItem("cycle"),
        },
      })
        .then((response) => {
          if (response.data.error == false) {
            Gems.Telegram("Operação realizada com sucesso!", "tele-success");
          } else {
            Gems.Telegram(response.data.message, "tele-not-allowed");
          }
          this.GetTeam();
          this.$emit("update-teams", event);
          this.event = false;
        })
    },

    async RemoveTemplate(user) {
      let remove = true;
      // if (user.has_results > 0) {
        await Gems.Telegram(
          'Remover este modelo/matriz? <br/>(Os resultados serão perdidos)',
          "tele-info",
          "",
          ""
        ).then(
          () => {},
          () => { remove = false }
        );
      // }

      if (!remove) return;

      this.ExcludeTemplate(user).then((response) => {
        if (response.data.error == false) {
          Gems.Telegram("Modelo removido", "tele-success");
        } else {
          Gems.Telegram(response.data.message, "tele-not-allowed");
        }
        
        this.GetTeam();
        this.$emit("update-teams", event);
        this.event = false;
      });
    },

    ExcludeTemplate(user) {
      return KPI.CallBackEnd("post", "remove-template", {
        deleteUser: {
          user: user,
          cycle: localStorage.getItem("cycle"),
        },
      });
    },

    async LeaderChecked(user) {
      let team = this.objFormation;

      for (let userTeam in team) {
        if (team[userTeam].user_id === user.user_id)
          team[userTeam].leader = !team[userTeam].leader
      }

      await KPI.CallBackEnd("post", "set-operations", {
        params: {
          set: "team-leader",
          userChanged: user,
          cycle_id: localStorage.getItem("cycle"),
          team_id: this.objTeam.team_id,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.error == false) {
              this.GetTeam();
              if (user.leader == 1) {
                Gems.Telegram("Líder Definido", "tele-success");
              } else {
                Gems.Telegram("Líder Removido", "tele-success");
              }
            } else {
              Gems.Telegram(response.data.message, "tele-not-allowed");
            }
          } else {
            Gems.Telegram("Erro ao definir o líder", "tele-not-allowed");
          }
        })
    },

    async DecreaseTeam(user) {
      if (user.has_results == 0) {
        await this.ExcludeTemplate(user);
      }

      const element = document.getElementById(
        "divUser" + this.objTeam.team_id + "_" + user.user_id
      );
      element.className = "divUserDeleted";

      await KPI.CallBackEnd("post", "decrease-team", {
        deleteUser: {
          user: user,
          cycle: localStorage.getItem("cycle"),
        },
      })

        .then((response) => {
          if (response.status == 200) {
            if (response.data.error == false) {
              Gems.Telegram("Colaborador excluído", "tele-success");
            } else {
              Gems.Telegram(response.data.message, "tele-not-allowed");
            }
          } else {
            Gems.Telegram("Erro ao excluir o colaborador", "tele-not-allowed");
          }
          this.GetTeam();
          this.$emit("update-teams", event);
          this.event = false;
          element.className = "divUser";
        })
    },

    async GetTeam() {
      await KPI.CallBackEnd("get", "get-teams", {
        params: {
          team_id: this.objTeam.team_id,
          cycle: localStorage.getItem("cycle"),
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.objFormation = response.data;
          } else {
            Gems.Telegram("Erro ao buscar equipa", "tele-not-allowed");
          }
        })
    },

    async SetTemplate(temp) {
      await KPI.CallBackEnd("post", "update-users-template", {
        users: this.usersId,
        template: temp.temp_id,
        scopes: temp["SCOPES"],
        volume: this.volume,
        cycle: localStorage.getItem("cycle"),
        team_id: this.objTeam.team_id,
      }).then((res) => {
        if (res.status == 200) {
          if (res.data.error == false) {
            Gems.Telegram("Operação realizada com sucesso!", "tele-success");
            this.GetTeam();
            this.usersId = [];
            this.$emit("update-teams");
          } else {
            Gems.Telegram(res.data.message, "tele-not-allowed");
          }
        } else {
          Gems.Telegram(
            "Erro ao alterar o modelo! Tente novamente",
            "tele-not-allowed"
          );
        }
        this.event = false;
      });
    },

    Enter(event, user, permission = null) {
      if (permission != false) {
        if (this.card == "Users") {
          document.getElementById("tr")?.classList.add("is-dragging");
        } else {
          if (user === 0) {
            for (let user in this.objFormation) {
              document
                .getElementById(
                  "divUser" +
                    this.objTeam.team_id +
                    "_" +
                    this.objFormation[user].user_id
                )
                .classList.add("dropzone");
            }
          } else {
            document
              .getElementById(
                "divUser" + this.objTeam.team_id + "_" + user.user_id
              )
              .classList.add("dropzone");
          }
        }
      }
    },

    ChangePointer() {
      this.event = !this.event;
    },

    Leave(event, user, permission = null) {
      if (permission != false) {
        if (this.card === "Users") {
          document.getElementById("tr").classList.remove("is-dragging");
        } else {
          if (user === 0) {
            for (let user in this.objFormation) {
              document
                .getElementById(
                  "divUser" +
                    this.objTeam.team_id +
                    "_" +
                    this.objFormation[user].user_id
                )
                .classList.remove("dropzone");
            }
          } else {
            document
              .getElementById(
                "divUser" + this.objTeam.team_id + "_" + user.user_id
              )
              .classList.remove("dropzone");
          }
        }
      }
    },
  },
  mounted() {
    this.objFormation = this.objTeam.users;
    if (this.$refs.deleteTeam) {
      Gems.Tooltip(this.$refs.deleteTeam, "Apagar equipa", "tooltip");
    }
  },
};
</script>

<style scoped>
.person {
  border-collapse: collapse;
}

.person-header {
  background-color: var(--special-app-color);
  display: grid;
  grid-template-columns: 0.3fr 1fr 1.5fr 0.2fr 0.2fr !important;
  grid-gap: 0px !important;
  align-items: center !important;
  grid-auto-rows: 25px !important;
}

.dropdown {
  align-items: center;
  display: flex;
  min-height: 1lh;
}

.team-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0px;
}

.open-team {
  flex: 1;
}

.open-team:hover,
.icon-div:hover {
  color: var(--default-app-color);
}

.divUser {
  text-align: left;
  padding: 0;
}

.divUserDeleted {
  text-align: left;
  border-radius: 3px;
  padding: 0px 3px 1px 2px;
  background-color: rgba(255, 0, 0, 0.575);
  color: white;
}

.person {
  width: 100%;
  margin-top: 10px;
}

.person thead tr {
  display: grid;
  grid-template-columns: 0.3fr 1.5fr 1fr 0.2fr 0.2fr;
  /* grid-gap: 10px; */
  align-items: center;
  font-weight: 500;
}

.fa-sort-up {
  transform: translateY(6px);
}

.fa-sort-down {
  transform: translateY(-4px);
}

.fa-trash-alt {
  transform: translateY(1px);
}

.left {
  margin-left: 10px !important;
}

.right {
  margin-right: 9px;
}

@media only screen and (max-width: 1400px) {
  .left {
    margin-left: 7px !important;
  }

  .right {
    margin-right: 9px;
  }
}
</style>
