<template>
  <div class="permissions-container">
    <div class="options">
      <input class="search" type="text" ref="txtName" @keyup="SearchUsers()" placeholder="Pesquisar colaborador..." />
    </div>
    <div class="users-list">

      <div id="legend-header" class="div-legend-header">

        <div class="div-legend-container div-legend-container-empty">
          <div class="div-legend-top div-legend-top-empty">

          </div>
          <div class="div-legend-bottom div-legend-bottom-empty">
            <p class="p-myself"></p>
            <p class="p-subordinate"></p>
          </div>
        </div>


        <div class="div-legend-container">
          <div class="div-legend-top">
            Editar Matriz
          </div>
          <div id="legend-bottom" class="div-legend-bottom">
            <p class="p-myself">Própria</p>
            <p class="p-subordinate">Subordinado</p>
          </div>
        </div>

      </div>

      <table class="table-user">
        <tbody class="tbody">
          <tr v-for="(objUser, row_count) in arrUsers" :key="objUser.user_id"
            :class="[row_count++ % 2 == 0 ? 'greyRow' : 'special-app-color-row']">
            <td>
              <div class="photo-user">
                <img :src="objUser.src" v-if="objUser.src != false && objUser.error == false"
                  @error="objUser.error = true" class="cover" />
                <i class="fas fa-user photo-icon fa-3x" aria-hidden="true" v-else></i>
              </div>
            </td>
            <td>
              <div class="internal" :ref="'userInternal' + objUser.user_id">
                {{ objUser.user_internal }}
              </div>
            </td>
            <td>
              <div class="name-detail" :ref="'userName' + objUser.user_id">
                {{ objUser.professional_name }}
              </div>
            </td>
            <td>
              <div :ref="'job' + objUser.user_id" @mouseenter="Gems.TooltipIfNeeded($refs['job' + objUser.user_id][0])"
                class="line-clamp" v-if="objUser.user_job">{{ objUser.user_job }}</div>
              <div v-else>[Sem função]</div>
            </td>
            <td class="btn">
              <input class="cursor-pointer" type="checkbox" name="" id="" v-model="objUser.checkOwnMatrix"
                @change="UpdatePermissions(objUser)" />
            </td>
            <td class="btn">
              <input class="cursor-pointer" type="checkbox" name="" id="" v-model="objUser.checkSubMatrix"
                @change="UpdatePermissions(objUser)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "permissions",
  data() {
    return {
      arrUsers: [],
      error: false,
      abortController: null,
      Gems,
    };
  },
  methods: {
    async SearchUsers() {
      if (this.$refs.txtName.value != "") {
        if (this.abortController != null) this.abortController.abort();

        this.abortController = new AbortController();
        const response = await KPI.CallBackEnd("get", "filter-permissions", {
          params: {
            search_text: this.$refs.txtName.value,
            admin: true,
            cycle: localStorage.getItem("cycle"),
            signal: this.abortController.signal,
          },
        })

        if (response.data[1] == this.$refs.txtName.value)
          this.arrUsers = response.data[0]

      } else if (this.$refs.txtName.value == "") {
        this.GetUsers(true);
      }
    },

    async GetUsers(admin) {
      this.arrUsers = [];
      await KPI.CallBackEnd("get", "filter-permissions", {
        params: {
          search_text: this.$refs.txtName.value,
          admin: admin,
          cycle: localStorage.getItem("cycle"),
        },
      })
        .then((res) => {
          this.arrUsers = res.data[0];
        })
    },

    async UpdatePermissions(user) {
      if (user.checkOwnMatrix == true) {
        if (user.checkSubMatrix == true) {
          user.id_permission = 3;
        } else {
          user.id_permission = 1;
        }
      } else if (user.checkSubMatrix == true) {
        user.id_permission = 2;
      } else {
        user.id_permission = 0;
      }

      await KPI.CallBackEnd("post", "update-users-permissions", {
        params: {
          userId: user.user_id,
          id_permission: user.id_permission,
          cycle_id: localStorage.getItem("cycle"),
        },
      })
        .then((res) => {
          if (res.status == 200) {
            if (res.data.error == false) {
              Gems.Telegram("Alterado com sucesso!", "tele-success");
            } else {
              Gems.Telegram(res.data.message, "tele-not-allowed");
              this.GetUsers(true);
            }
          }
        })
    },

    handleScroll() {
      var divHeader = document.getElementById("legend-header");

      if (divHeader) {
        var sticky = divHeader.offsetTop;

        if (document.body.scrollTop > sticky) {
          divHeader.classList.add("sticky");
          document.getElementById('legend-bottom').classList.add('div-legend-bottom-sticky');

          document.querySelector('.div-legend-container-empty').classList.add('div-legend-container-empty-sticky');
          document.querySelector('.users-list').style.marginTop = '47px';
        }

        if (document.body.scrollTop < 70) {
          divHeader.classList.remove("sticky");
          document.getElementById('legend-bottom').classList.remove('div-legend-bottom-sticky');

          document.querySelector('.div-legend-container-empty').classList.remove('div-legend-container-empty-sticky');
          document.querySelector('.users-list').style.marginTop = '0px';

        }
      }
    },
  },
  mounted() {
    this.GetUsers(true);
    window.addEventListener("scroll", this.handleScroll, true);
  },
  updated() {
    if (this.arrUsers) {
      for (let user in this.arrUsers) {
        let user_name = "userName" + this.arrUsers[user].user_id;
        let user_internal = "userInternal" + this.arrUsers[user].user_id;
        if (this.$refs[user_name]) {
          Gems.Tooltip(
            this.$refs[user_name][0],
            this.arrUsers[user]["complete_name"],
            "tooltip"
          );
        }

        if (this.$refs[user_internal]) {
          Gems.Tooltip(this.$refs[user_internal][0], "N° interno", "tooltip");
        }
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', window)
  }
};
</script>

<style scoped>
.permissions-container {
  padding: 15px 16px 15px 16px;
}

.options {
  height: 50px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  vertical-align: middle;
}

.table-user {
  width: 100%;
}

.table-user tbody tr {
  margin: 2.4px 0;
  font-size: 10pt !important;
}

.table-user tbody tr:hover {
  color: blueviolet;
}

tr {
  display: grid;
  grid-template-columns: 0.5fr 0.8fr 3.1fr 2fr 1fr 1fr;
  grid-gap: 10px;
  align-items: center;

  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  grid-auto-rows: 45px;
  margin-top: -2px;
}

.tr-tablehead {
  border-bottom: 1px solid black;
  border-radius: 0px !important;
}

.photo-user {
  pointer-events: none;
  border: 2px solid white;
  width: 41px;
  height: 40px;
  border-radius: 50%;
  margin-left: 0px;
  box-shadow: 0 0 10px 1px rgb(0, 0, 0, 0.1);
  text-align: center;
  outline: solid 1px var(--light-gray-color);
}

.photo-user img {
  width: 41px;
  height: 40px;
  border-radius: 50%;
}

.cover {
  object-fit: cover;
  width: 150px;
  height: 100px;
}

.icon-user {
  width: 75%;
  height: 75%;
  margin: 4px auto auto 8px;
}

.name-detail {
  font-weight: bold;
  padding: 10px 5px 10px;
  cursor: default;
}

.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
}

.search {
  width: 100%;
  height: 25px;
}

.photo-icon {
  width: 41px;
  height: 40px;
  font-size: 22pt;
  padding-top: 4px;
}

.inner-table {
  width: 100%;
  text-align: center;
  border: 0px solid;
}

.inner-table tbody tr {
  grid-template-columns: 1fr 1fr !important;
  grid-auto-rows: 38px;
}

.inner-table thead tr {
  grid-template-columns: 1fr !important;
  height: 10px;
  grid-auto-rows: 30px;
}

.inner-table tbody td {
  font-size: 13px !important;
  color: black !important;
  height: 10px !important;
  text-align: center !important;
}

.inner-table tbody td {
  font-size: 11px !important;
}

.table-user thead tr {
  grid-template-columns: 0.47fr 2.85fr 2.85fr 2fr;
}

.sticky {
  position: absolute;
  top: 0px;
  z-index: 2;
}

.sticky .div-legend-container {
  -webkit-box-shadow: 0px 6px 10px -5px rgb(0 0 0 / 70%);
  -moz-box-shadow: 0px 6px 10px -5px rgb(0 0 0 / 70%);
  box-shadow: 0px 6px 10px -5px rgb(0 0 0 / 70%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sticky .div-legend-bottom-empty,
.sticky .div-legend-top-empty {
  background-color: transparent !important;
}

.sticky+.content {
  padding-top: 102px;
}

.internal {
  font-weight: 500;
  cursor: default;
}

.div-legend-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
  padding-top: 5px;
}

.div-legend-container-empty {
  width: 762px;
  box-shadow: none !important;
}

.div-legend-top {
  width: 247px;
  background-color: #fff;
  height: 17px;
  padding-bottom: 5px;

}

.div-legend-top-empty {
  height: 17px;
}

.div-legend-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--default-app-color);
  color: white;
  height: 25px;
}

.div-legend-bottom-empty {
  width: 100%;
}

.p-myself {
  width: 130px;
  margin: 0;
}

.p-subordinate {
  width: 117px;
  margin: 0;
}

input[type=checkbox] {
  transform: scale(1.5);
}

.div-legend-header {
  display: flex;
}

@media only screen and (max-width: 1400px) {
  .div-legend-container-empty-sticky {
    width: 554px;
  }
}
</style>
