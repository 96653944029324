const state = {
  switchValue: false,
  id: Number,
  sitemap: []
};

const getters = {
  stateSitemap: (state) => {
    return state.sitemap;
  },
  idStore: (state) => {
    return state.id;
  },
};

const actions = {
  StoreSitemap({ commit }, sitemap) {
    commit("storeSitemap", sitemap);
  },

  StoreId({ commit }, id) {
    commit("storeId", id);
  },

  RestoreSitemap({ commit }) {
    commit("restoreSitemap");
  },
};

const mutations = {
  storeSitemap: (state, sitemap) => (state.sitemap = sitemap),
  storeId: (state, id) => (state.id = id),
  restoreSitemap: (state) => (state.sitemap = []),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
