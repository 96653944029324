<template>
  <div class="menu-container">
    <div class="top-menu">
      <div class="kpi"></div>
    </div>

    <div
      class="cycle-box default-app-bg cursor-pointer"
      @click="$router.push({ name: 'cycles' })"
      v-if="arrCycle"
    >
      <p class="title-cycle-box" v-if="arrCycle">
        {{ arrCycle.cycle_desig }}
      </p>
      <p class="title-cycle-box" v-else>-</p>
      <p v-if="Object.keys(arrCycle).length">
        {{ arrCycle.cycle_begin }}&#32;&#32;
        <i class="fas fa-arrows-alt-h fa-lg" style="margin: 4px 15px"></i>
        &#32;&#32;{{ arrCycle.cycle_end }}
      </p>
      <p v-else>
        -&#32;&#32;<i
          class="fas fa-arrows-alt-h fa-lg"
          style="margin: 4px 15px"
        ></i
        >&#32;&#32;-
      </p>
    </div>
    <div v-if="user.length > 0">
      <div class="menu-list cursor-pointer" :key="menu.id" v-for="menu in propArrMenu" v-show="ShowMenu(menu)">
        <router-link :to="`${menu.toView}`" @click="GoTo(menu)" v-if="menu.isRouter">
          <i :class="menu.icon" class="icon fa-1x users" aria-hidden="true" v-if="menu.id === 4"></i>
          <i :class="menu.icon" class="icon fa-1x" aria-hidden="true" v-else></i>
          <div>
            <p class="menu-text">{{ menu.text }}</p>
            <i
              class="fas fa-chevron-right active-icon fa-2x"
              aria-hidden="true"
              v-if="$router.currentRoute._value.path === menu.toView"
            ></i>
            <i
              class="fas fa-chevron-right active-icon fa-2x"
              aria-hidden="true"
              v-else-if="
                $router.currentRoute._value.meta.masterNode === menu.toView
              "
            ></i>
          </div>
        </router-link>
        <a @click="DownloadFile('help.pdf')" v-else>
          <i :class="menu.icon" class="icon fa-1x" aria-hidden="true"></i>
          <div>
            <p class="menu-text">{{ menu.text }}</p>
          </div>
        </a>
      </div>
    </div>
    <div class="user-box cursor-pointer"
      @click="OpenLoggedProfile()"
      v-if="user.length > 0"
    >
      <div class="divEntity">{{ entity }}</div>
      <h2 class="title-user-box"> {{ user[0].professional_name }} </h2>
    </div>
    <div class="logo-box" v-if="src">
      <img v-if="error === false" :src="src" @error="error = true" class="cover" />
    </div>
    <div class="cirvalues">
      <div class="pills">
        <a class="pill" id="comp" target="_blank">COMP</a>
        <a class="pill" id="360" target="_blank">360</a>
      </div>
    </div>
  </div>
</template>

<script>
import { Gems } from '../gems';
import { KPI } from "../kpi";
import { mapActions } from "vuex";

export default {
  name: "menu-list",
  props: {
    propArrMenu: Array,
    arrCycle: [Array, Object],
    reloadMenu: Boolean,
  },
  data() {
    return {
      cycleID: Number,
      showSummary: false,
      user: [],
      error: false,
      src: '',
      auxMenu: [],
      entity: "",
    };
  },
  watch: {
    arrCycle: async function () {
      this.src = this.arrCycle.logoSrc
    },
  },
  methods: {
    ...mapActions(["RestoreSitemap"]),

    ShowMenu(menu) {
    
      let admin = KPI.isAdmin()

      if (menu.id == 5 && this.user[0].has_subordinates == 0 && !admin)
        return false

      if (!admin && menu.requiresAdmin === true) 
        return false

      return true
    },
    GoToProfile() {
      if (this.$router.currentRoute._value.path === "/profile") 
        this.$emit("force-reload")
      
      this.$router.push({ name: "profile" })
    },

    async OpenProfile() {
      this.$router.push({
        name: "profile",
        params: { userId: parseInt(this.user[0].user_id) },
      });
    },

    async GetUserName() {
      const response = await KPI.CallBackEnd("get", "get-user")

      this.user = response.data[0]
      this.entity = response.data[1]
    },

    GetClass(menu) {
      if (this.$router.currentRoute._value.meta.masterNode === menu)
        return "active"
        
      return ""
    },

    async DownloadFile(file_name) {
      const response = await KPI.CallBackEnd("get", "download-file", {
        responseType: "blob",
        params: { file_name: file_name },
      })

      const fileRes = new Blob([response.request.response], {
        type: "application/octet-stream",
      })

      const url = window.URL.createObjectURL(fileRes)
      const a = document.createElement("a")
      a.className = "hidden"
      a.href = url
      a.download = 'Cirvalue_KPI_Ajuda.pdf'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      Gems.Telegram('Certifique-se de que o seu browser não está a bloquear o download', "tele-success")
    },

    OpenLoggedProfile() {
      this.RestoreSitemap()

      if(this.$router.currentRoute._value.path === '/profile')
        this.$emit('force-reload')
      else {
        this.$router.push({ name: 'profile', state: { id: null } })
      }
    },

    GoTo(menu) {
      if(menu.toView == '/users')
        this.RestoreSitemap()

      if(this.$router.currentRoute._value.path === menu.toView)
        this.$emit('force-reload')
    }
  },
  async created() {
    await this.GetUserName();

    // let admin = KPI.isAdmin()

    // this.propArrMenu.forEach((menu, i) => {
    //   if (menu.id == 5 && this.user[0].has_subordinates == 0 && !admin) {
    //     // eslint-disable-next-line vue/no-mutating-props
    //     this.propArrMenu.splice(i, 1);
    //   }

    //   if (!admin && menu.requiresAdmin === true) {
    //     // eslint-disable-next-line vue/no-mutating-props
    //     this.propArrMenu.splice(i, 1);
    //   }
    // });

  },
  mounted() {
      document
        .getElementById("comp")
        .setAttribute("href", `https://${Gems.EnvPrefix()}comp.inpeoplehub.com/`)
      document
        .getElementById("360")
        .setAttribute("href", `https://${Gems.EnvPrefix()}360.inpeoplehub.com/`)
  },
};
</script>

<style scoped>
.menu-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 6px 0px 12px rgba(30, 60, 90, 0.15);
  width: 260px;
}

.menu-list {
  height: 54px;
  color: var(--font-color);
  border-bottom: solid 1px #f4f4f4;
  z-index: 1;
  position: relative;
}

.menu-list a {
  display: flex;
  height: 100%;
  width: 100%;
}

.menu-list a div {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.menu-list a:last-child {
  border-bottom: none;
}

.menu-list a:hover {
  color: var(--default-app-color);
  background-color: #f7f2fe;
}

.icon {
  width: 20px;
  align-self: center;
  margin: 2px 15px 0 15px;
  font-size: 1.3em;
}

.title-cycle-box {
  font-weight: bold;
  font-size: 12px;
}

.users {
  font-size: 1.4em;
}

.active-icon {
  width: 20px;
  align-self: center;
  margin-right: 15px;
  font-size: 1.5em;
}

.menu-text {
  font-size: 1.125em;
  text-transform: uppercase;
  align-self: center;
}

.active {
  background-color: #f7f2fe;
}

.cycle-box {
  padding: 2%;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.cycle-box p {
  margin: 0px;
}

.user-box {
  text-align: center;
  background-color: #f4f4f4;
  border-bottom: solid 1px #f4f4f4;
  height: 54px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
}

.logo-box {
  cursor: default;
  background-color: white;
  padding: 0px 12% 0px 13%;
}

.cirvalues {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

.pills {
  display: flex;
  justify-content: center;
  position: relative;
}

.pill {
  background-color: var(--second-app-color);
  color: white;
  margin: 5px 5px;
  width: 25%;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  font-size: small;
  padding: 0.962%;
}

.pill:hover {
  color: black;
}

.kpi {
  background-size: 80%;
  background-position: 75%;
  background-repeat: no-repeat;
  padding: 20px 10px 0px;
  height: 80px;
  background-image: url("../images/logo.png");
  background-color: var(--font-color);
}

.user-box:hover {
  background-color: var(--medium-app-color);
  color: var(--default-app-color);
}

.cover {
  object-fit: cover;
  width: 200px;
  margin: 0 auto;
}

.logout {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  transform: scaleX(-1);
}

.divEntity {
  font-size: 5em;
  color: var(--second-app-color);
  overflow: hidden;
  text-align: left;
  margin-top: -4px;
  opacity: 0.2;
  line-height: 1;
  position: absolute;
}

.title-user-box {
  color: var(--font-color);
  padding: 0px;
  width: 288px;
  height: inherit;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  font-weight: bold;
  font-size: 14pt;
  position: relative;
}

@media only screen and (max-width: 1400px) {
  .menu-text {
    font-size: 14px;
  }

  .menu-container {
    width: 250px;
  }

  .logo-box {
    padding: 0px 20px 0px 20px;
  }
}
</style>
