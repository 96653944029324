<template>
  <table class="tbl-indicators">
    <thead :class="`thead-indicators ind_color_${scopeID}`">
      <tr>
        <td></td>
        <td class="td-id"></td>
        <td class="td-desig">Indicador</td>
        <td class="td-weight">Peso</td>
        <td class="td-metric">Métrica</td>
        <td class="td-unit">Unid.</td>
        <td class="td-goal">Objetivo</td>
        <td class="tdMark">Valor crítico</td>
        <td class="tdVol">Resultado</td>
        <td class="td-realization">Realização</td>
        <td class="tdAvaliation">Avaliação</td>
        <td class="tdAbove100">> 100%</td>
        <td class="td-gauge"></td>
        <td class="td-gauge"></td>
      </tr>
    </thead>
    <tbody
      :key="indicator.ind_id"
      v-for="(indicator, row_count) in userIndicators"
      :class="[row_count++ % 2 == 0 ? '' : 'special-app-color-row']"
    >
      <MatrixIndicatorBlock
        v-if="scopeID == 3  && permission"
        :objIndicator="indicator"
        :scopeId="scopeID"
      />
      <MatrixIndicatorBlockNotEditable
        v-else-if="scopeID != 3 || !permission"
        :objIndicator="indicator"
        :teamId="teamId"
      />
    </tbody>
  </table>
</template>

<script>
import MatrixIndicatorBlock from "./MatrixIndicatorBlock";
import MatrixIndicatorBlockNotEditable from "./MatrixIndicatorBlockNotEditable";

export default {
  name: "MatrixIndicatorList",
  props: {
    userIndicators: Array,
    scopeID: [String, Number],
    company: Boolean,
    permission: Boolean,
    teamId: [String, Number],
  },
  components: {
    MatrixIndicatorBlock,
    MatrixIndicatorBlockNotEditable,
  },
  methods: {},
};
</script>

<style scoped>

.td-desig {
  min-width: 20%;
}

.td-weight {
  width: 8%;
}

.td-unit {
  width: 4%;
  text-align: center;
}

.td-goal {
  width: 8%;
  text-align: center;
}

.tdMark {
  width: 8%;
  text-align: center;
}

.tdVol {
  width: 10%;
  text-align: center;
}

.tdAvaliation {
  width: 7%;
  text-align: center;
}

.td-gauge {
  width: 3%;
}

.tdAbove100 {
  width: 5%;
  text-align: center;
}
</style>
