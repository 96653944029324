<template>
  <div class="divContent">
    <form @submit.prevent="ConfirmCreateTemplate(txtDesig)">
      <div>Descritor</div>

      <input type="text" class="txtDesig" v-model="txtDesig" required />
      <button type="submit" class="btnCreate btn-action">CRIAR MODELO</button>

      <div>Descritivo</div>
      <div>
        <input type="text" class="txtDesc" v-model="txtDesc" />
      </div>
    </form>
    <div class="divPadding">
      <TemplatesPreviewList
        :arrTemplates="arrTemplates"
        @clicked="TriggeredFromChild"
      />
    </div>
  </div>
</template>

<script>
import TemplatesPreviewList from "../components/TemplatesPreviewList";
import { KPI } from "../kpi";
import { Gems } from "../gems";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "templates",
  components: {
    TemplatesPreviewList,
  },
  data() {
    return {
      txtDesig: "",
      txtDesc: "",
    };
  },
  computed: {
    ...mapGetters(['arrTemplates'])
  },
  methods: {
    
    ...mapActions(['IsTemplateNameValid', 'GetTemplates']),

    async ConfirmCreateTemplate(designation) {
      if(await this.IsTemplateNameValid({temp_id: null, temp_desig: designation})) {
        await this.CreateTemplate()
        this.txtDesig = ''
        this.txtDesc = ''
      }
    },

    async CreateTemplate() {
      const response = await KPI.CallBackEnd("post", "create-template", {
        desig: this.txtDesig,
        desc: this.txtDesc,
        cycle: localStorage.getItem("cycle"),
      })

      if (response.status != 200) {
        Gems.Telegram(KPI.ERROR_MESSAGE_GENERIC, "tele-not-allowed")
        return
      }

      Gems.Telegram("Modelo criado", "tele-success")
      this.GetTemplates()
    },

    TriggeredFromChild(value) {
      if (value === "RELOAD_TEMPLATES") {
        this.GetTemplates()
      }
    },
  },
  created() {
    this.GetTemplates()
  },
};
</script>

<style>

@media only screen and (max-width: 1400px) {
  .divPadding .card {
    width: 144px;
  }
}
</style>

<style scoped>
.txtDesig {
  width: 81%;
  margin-right: 2%;
}
.txtDesc {
  width: 98%;
}
.btnCreate {
  width: 15%;
  height: 29px;
  vertical-align: top;
}
.divContent {
  margin: 10px;
  width: 98%;
}

</style>
